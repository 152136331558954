/* line 2, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.issue-preview.scss */
.issue-preview {
  background: #f6f6f6;
  padding: 1.5rem 0;
  border-radius: 10px;
  text-align: left; }

/* line 8, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.issue-preview.scss */
.issue-preview__inner {
  margin: 0 auto;
  max-width: 600px; }
