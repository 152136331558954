/*
 * Body resets
 *
 * Update the foundational and global aspects of the page.
 */
/* line 9, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/base.layout.scss */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/* line 15, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/base.layout.scss */
html,
body {
  margin: 0;
  padding: 0;
  font-family: Helvetica, Arial, sans-serif; }

/* line 22, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/base.layout.scss */
.content__inner {
  position: relative; }

/* line 26, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/base.layout.scss */
.footer,
.content {
  margin-left: auto;
  margin-right: auto;
  padding: 2.5rem 1rem 1rem 1rem; }

/* line 32, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/base.layout.scss */
.footer {
  font-size: 14px;
  color: #aaa;
  border-top: 2px solid #eee;
  text-align: center; }

@media screen and (min-width: 767px) {
  /* line 40, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/base.layout.scss */
  .content,
  .footer {
    max-width: 740px; } }

/*
@include tablet_landscape_and_bigger {
  .content,
  .footer {
    margin-left: 16rem;
    padding-left: 3rem;
    padding-right: 1rem;
  }
}

@include desktop_and_bigger {
  .content,
  .footer {
    margin-left: 20rem;
    max-width: 890px;
  }
}
*/
/* line 63, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/base.layout.scss */
.show-only-on-desktop {
  display: none; }

@media screen and (min-width: 1279px) {
  /* line 67, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/base.layout.scss */
  .show-only-on-desktop {
    display: inline-block; }
  /* line 70, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/base.layout.scss */
  .hide-on-desktop {
    display: none; } }
