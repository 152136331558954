/*
 * Sidebar
 *
 * Flexible banner for housing site name, intro, and "footer" content. Starts
 * out above content in mobile and later moves to the side with wider viewports.
 */
/* line 9, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
.sidebar {
  width: 100%;
  position: fixed;
  z-index: 100000;
  overflow-y: auto;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  background-color: #1c1539; }

/* Sidebar links */
/* line 20, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
.sidebar a {
  color: #fff; }

/* line 23, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
.sidebar__text {
  text-align: center;
  font-size: 0.875rem;
  padding: 0.25rem; }

/* line 28, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
.sidebar__text code {
  background: #fff;
  color: #fff; }

/* line 32, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
.sidebar-header {
  text-align: center;
  font-size: 0.875rem;
  padding: 0.25rem; }
  /* line 36, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
  .sidebar-header img {
    width: 32px;
    height: 32px;
    border-radius: 25%;
    vertical-align: top; }
  /* line 42, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
  .sidebar-header h1 {
    display: inline; }
    /* line 44, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
    .sidebar-header h1 span {
      display: none;
      font-weight: normal;
      font-size: 1rem;
      white-space: nowrap;
      opacity: 0.5; }
  /* line 53, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
  .sidebar-header p {
    margin: 0;
    display: none; }
  /* line 57, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
  .sidebar-header .btn--subscribe {
    position: absolute;
    z-index: 1000;
    right: 6px;
    top: 2px;
    padding: 0.33333rem 0.888888rem;
    font-size: 0.888888rem;
    line-height: 1.333333;
    border-bottom-width: 1px; }

/* line 69, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
.sidebar-nav {
  display: none; }

/* About section */
/* line 74, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
.sidebar-header h1 {
  color: #fff;
  font-family: sans-serif;
  font-size: 1.25rem; }

/* Sidebar nav */
/* line 81, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
.sidebar-nav-item {
  display: block;
  line-height: 1.75;
  background: #282e3c;
  border-top: 1px solid #1c1539;
  padding: 0.25rem; }

/* line 88, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
a.sidebar-nav-item:hover,
a.sidebar-nav-item:focus {
  text-decoration: underline; }

/* line 92, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
.sidebar-nav-item.active {
  font-weight: bold;
  background: #271134; }

/* line 96, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
#sidebar-checkbox:checked ~ .sidebar-nav {
  display: block; }

/* Hide the sidebar checkbox that we toggle with `.sidebar-toggle` */
/* line 102, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
.sidebar-checkbox {
  display: none; }

/* Style the `label` that we use to target the `.sidebar-checkbox` */
/* line 107, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
.sidebar-toggle {
  position: absolute;
  top: 0.2rem;
  left: 1rem;
  display: block;
  width: 2.2rem;
  padding: 0.4rem 0;
  color: #FFFFFF;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  line-height: 18px; }

/*
.sidebar-toggle:before {
  display: block;
  content: "";
  width: 100%;
  padding-bottom: .125rem;
  border-top: .375rem double;
  border-bottom: .125rem solid;

  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
*/
/* line 134, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
.sidebar-toggle__notification-indicator {
  display: block;
  background-color: #F9BE33;
  border: 2px solid #1c1539;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: -0.2rem;
  left: 1.9rem; }

/* line 146, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
.sidebar-toggle:active,
#sidebar-checkbox:checked ~ .sidebar-toggle {
  opacity: 0.6; }

@media screen and (min-width: 321px) {
  /* line 155, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
  .sidebar-header h1 span {
    display: inline; } }

/*

@include tablet_landscape_and_bigger {
  .sidebar-header {
    h1 {
      display: none;
    }
  }
}
*/
/* Move sidebar to the left */
@media screen and (min-width: 1279px) {
  /* line 173, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
  .sidebar-toggle {
    position: fixed;
    width: 2.25rem; }
  /* line 177, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
  .sidebar-toggle:before {
    padding-bottom: .15rem;
    border-top-width: .45rem;
    border-bottom-width: .15rem; }
  /* line 183, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 14rem;
    padding: 2rem 1rem 1rem 1rem;
    border-bottom-color: transparent;
    border-right-color: #fff; }
  /* line 197, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
  .sidebar-header h1 {
    display: block; }
  /* line 200, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
  .sidebar-header img {
    width: 128px;
    height: 128px; }
  /* line 204, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
  .sidebar-header p {
    display: block; }
  /* line 207, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
  .sidebar-header .btn--subscribe {
    display: none; }
  /* line 212, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
  .sidebar-nav-separator {
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 1px solid #676f82; }
  /* line 219, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
  .sidebar-nav-item.active {
    background: transparent; }
  /* line 223, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
  .sidebar-toggle {
    display: none; }
  /* line 226, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
  .sidebar-nav {
    display: block;
    transition-property: all;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1); }
  /* line 233, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.todo.sidebar.scss */
  .sidebar-nav-item {
    background: transparent;
    border: none; } }
