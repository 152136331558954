@media screen and (min-width: 1279px) {
  /* line 8, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/keep.scss */
  .panel {
    position: fixed;
    z-index: 50;
    top: 0;
    height: 100%;
    overflow: scroll;
    width: 640px;
    background: #fff; } }

/* line 20, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/keep.scss */
.panel__inner {
  position: relative;
  padding: 20px; }

/* line 24, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/keep.scss */
.panel__close {
  position: absolute;
  z-index: 51;
  top: 0;
  right: 0;
  font-size: 32px;
  line-height: 32px;
  padding: 10px;
  color: #aaa; }
  /* line 33, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/keep.scss */
  .panel__close:hover {
    color: #666;
    cursor: pointer; }

/* line 39, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/keep.scss */
.panel--right {
  right: 0; }

/* line 43, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/keep.scss */
.url {
  font-size: 0.75rem;
  color: #aaa; }

/* line 48, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/keep.scss */
form.button,
form.link {
  display: inline; }

/* line 52, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/keep.scss */
.start-teaser {
  text-align: center; }

/* line 58, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/keep.scss */
.percentage {
  font-size: 0.675rem;
  color: #999; }

/* line 62, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/keep.scss */
th.date {
  width: 140px; }

/* line 66, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/keep.scss */
.email--failure {
  color: #f44; }

/* line 70, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/keep.scss */
.email--subscribed,
.email--verified {
  color: #693; }
