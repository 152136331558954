/* line 3, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.btn.scss */
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  border-bottom: 3px solid transparent;
  border-radius: 4px;
  white-space: nowrap;
  padding: 0.555555rem 0.888888rem;
  font-size: 0.888888rem;
  line-height: 1.333333; }

/* line 19, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.btn.scss */
.btn.focus,
.btn:focus,
.btn:hover {
  text-decoration: none; }

/* line 25, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.btn.scss */
.btn--small {
  padding: 0.333333rem 0.888888rem;
  font-size: 0.75rem;
  line-height: 1.0;
  border-bottom: 1px solid transparent; }

/* line 32, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.btn.scss */
.btn--default {
  color: #333;
  border-color: #ddd;
  background-color: #f0f0f0; }

/* line 38, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.btn.scss */
.btn--subscribe {
  color: #fff;
  background-color: #8e5ab5;
  border-color: #5c3777; }

/* line 43, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.btn.scss */
.btn--subscribe:hover {
  color: #fff;
  background-color: #a974d2;
  border-color: #8e5ab5; }

/* line 49, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.btn.scss */
.btn--tweet {
  color: #fff;
  text-decoration: none;
  background-color: #428bca;
  border-color: #357ebd; }
  /* line 54, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.btn.scss */
  .btn--tweet:visited, .btn--tweet:link, .btn--tweet:active {
    color: #fff; }

/* line 60, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.btn.scss */
.btn--tweet:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74; }

/* line 66, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.btn.scss */
.btn--primary {
  color: #fff;
  background-color: #87AE10;
  border-color: #687B2B; }

/* line 71, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.btn.scss */
.btn--primary:hover {
  color: #fff;
  background-color: #9BC714;
  border-color: #819836; }

/* line 77, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.btn.scss */
.btn--danger {
  color: #fff;
  background-color: #c33;
  border-color: #913939; }

/* line 82, /home/rrrene/projects/weekly/apps/elixir_weekly/web/static/css/app/component.btn.scss */
.btn--danger:hover {
  color: #fff;
  background-color: #F01515;
  border-color: #B84040; }
